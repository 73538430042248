<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Country Code</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form @submit="handleChangeCountryCode" v-slot="{ errors }">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Country Code</label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.country_code }">
                                        <Field autocomplete="off" name="country_code" v-model="country_code" rules="required" lable="country code">
                                            <multiselect 
                                                v-model="country_code"
                                                label="name"
                                                :searchable="true"
                                                value-prop="dial_code"
                                                :options="countryCodes"
                                                placeholder="Select Country Code"
                                            >
                                                <template v-slot:singlelabel="{ value }">
                                                    <div class="multiselect-single-label">
                                                        {{ value.name }} ({{ value.dial_code }})
                                                    </div>
                                                </template>
                                                <template v-slot:option="{ option }">
                                                    {{ option.name }} ({{ option.dial_code }})
                                                </template>
                                            </multiselect>
                                        </Field>
                                    </div>
                                <ErrorMessage name="country_code" class="text-danger" />
                            </div>
                        </div>
                        <div class="action_wpr mt-5">
                            <button :disabled="bulkActionLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="bulkActionLoader" class="btn save_btn"><i class="fa fa-spinner fa-spin" v-if="bulkActionLoader"></i>{{ bulkActionLoader ? ' Updating' : 'Update' }}</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import constants  from '@/utils/Constant'

    export default {
        name: 'CountryCode',

        data () {
            return {
                countryCodes: [],
                country_code: null,
            }
        },

        props: {
            modelValue: Boolean,
            contacts: [Object, Array],
        },

        emits: ['update:modelValue'],

        components: {
            Form,
            Field,
            ErrorMessage,
        },

        computed: mapState({
            bulkActionLoader: state => state.contactModule.bulkActionLoader,
        }),

        mounted () {
            const vm = this;

            vm.countryCodes = constants.phone_code_list;
        },
        watch:{
            modelValue(val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },
        methods: {
            ...mapActions({
                changePhoneCountryCode: 'contactModule/changePhoneCountryCode',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleChangeCountryCode(params, { setFieldError }) {
                const vm = this;

                params.contacts = vm.contacts;
                params.setFieldError = vm.setFieldError;
                params.closeModal = vm.closeModal;

                vm.changePhoneCountryCode(params);
            }
        },
    }
</script>
